import 'swiper/css';
import 'swiper/css/navigation';

import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
// import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth"
import { Divider, Grid, Tab, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { A11y, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import constMeta from '@/contents/constants/constMeta';
import Button from '@/contents/General/Button';
import Icon from '@/contents/General/Icon';
import Image from '@/contents/General/Image';
import Stack from '@/contents/General/Stack';
import Tabs from '@/contents/General/Tabs';
import Typography from '@/contents/General/Typography';
import { GlobalContext } from '@/contents/hooks/state';
import LayoutOnlyBody from '@/layouts/LayoutOnlyBody';
import OptimizedImage from '@/modules/General/OptimizedImage';
import theme from '@/styles/theme';
import type { NextPageWithLayout } from '@/types';
import { Page } from '@/types/domain';

const Container = styled(Box)<{ is_pc: string }>`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  min-height: 100vh;
  background: #f4f8f6;
  ${(props) => props.is_pc && `max-width: 506px;`}
`;

const StyledSwiper = styled(Swiper)`
  .swiper-slide-next {
    opacity: 0.7;
  }
  .swiper-slide-prev {
    opacity: 0.7;
  }
  .swiper-button-prev {
    width: 30px;
    background: url('/assets/images/icons/icon_arrowleft_round_primary.svg')
      no-repeat;
  }
  .swiper-button-next {
    width: 30px;
    background: url('/assets/images/icons/icon_arrowright_round_primary.svg')
      no-repeat;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    display: none;
  }
  .swiper-button-prev:focus,
  .swiper-button-next:focus {
    outline: none;
  }
  .swiper-slide img {
    object-fit: cover;
  }
`;

const Index: NextPageWithLayout = () => {
  const router = useRouter();
  const { status } = router.query;
  const { setPage } = useContext(GlobalContext);
  const [tabValue, setTabValue] = useState<string>('');
  const [lastLogin, setLastLogin] = useState('');
  const isPC = useMediaQuery('(min-width:900px)');

  const changeTab = (
    event: React.SyntheticEvent<Element, Event>,
    value: any
  ) => {
    setTabValue(value);
    router.replace(`/login/?status=${value}`);
  };

  useEffect(() => {
    if (status) {
      setTabValue(status as string);
    } else {
      setTabValue('');
    }
  }, [status]);

  useEffect(() => {
    setLastLogin(localStorage.getItem('last_login') ?? '');
    setPage(Page.LOGIN);
    // @ts-ignore
    window.Auth = Auth;
  }, []);
  return (
    <>
      <Head>
        <title>{constMeta.login.title}</title>
        <meta name="description" content={constMeta.login.mata_description} />
        <meta property="og:title" content={constMeta.login.title} />
        <meta
          property="og:description"
          content={constMeta.login.mata_description}
        />
        <meta
          property="og:image"
          content="https://iihi.life/assets/images/common/ogp.jpg"
        />
      </Head>
      <Container is_pc={isPC ? isPC.toString() : ''}>
        <Box sx={{ padding: `0 ${theme.spacing(3)}` }}>
          <Stack column center space={3} paddingTop={5} paddingBottom={7}>
            <Image
              src="/assets/images/common/logo_black.svg"
              width={139}
              height={79}
              alt="logo"
            />
            <Typography
              fontSize={13}
              lineHeight={1.8}
              letterSpacing="0.24em"
              textAlign="center"
            >
              みんなでつくる
              <br />
              スマホの中のキャンプギア
            </Typography>
          </Stack>
          <Stack center paddingBottom={6}>
            <Tabs tabValue={tabValue} onChange={changeTab}>
              <Tab value="" label="ログイン" />
              <Tab value="signup" label="新規登録" />
            </Tabs>
          </Stack>
          <Stack column space={1} paddingBottom={4}>
            <Typography fontSize={16} fontWeight={700}>
              {tabValue === ''
                ? 'SNSアカウントでログインする'
                : 'SNSアカウントで登録する'}
            </Typography>
            <Divider />
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Stack column center>
                <Button
                  buttonSize="md"
                  color="line"
                  startIcon={<Icon iconType="sns-line" />}
                  fullWidth
                  sx={{
                    justifyContent: 'start',
                    paddingLeft: theme.spacing(2),
                  }}
                  onClick={() => {
                    Auth.federatedSignIn({
                      customProvider: 'Line',
                    }).then(() => {
                      localStorage.setItem('last_login', 'LINE');
                    });
                  }}
                >
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    color="white"
                    paddingLeft={1}
                  >
                    LINE
                  </Typography>
                </Button>
                {lastLogin === 'LINE' && (
                  <Typography
                    fontSize={12}
                    fontWeight={700}
                    color="primary"
                    paddingTop={1}
                  >
                    前回のログインはこちら
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack column center>
                <Button
                  buttonSize="md"
                  color="white"
                  startIcon={<Icon iconType="sns-google" />}
                  fullWidth
                  sx={{
                    justifyContent: 'start',
                    paddingLeft: theme.spacing(2),
                  }}
                  onClick={() => {
                    Auth.federatedSignIn({
                      provider: CognitoHostedUIIdentityProvider.Google,
                    }).then(() => {
                      localStorage.setItem('last_login', 'GOOGLE');
                    });
                  }}
                >
                  <Typography fontSize={16} fontWeight={500} paddingLeft={1}>
                    Google
                  </Typography>
                </Button>
                {lastLogin === 'GOOGLE' && (
                  <Typography
                    fontSize={12}
                    fontWeight={700}
                    color="primary"
                    paddingTop={1}
                  >
                    前回のログインはこちら
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack column center>
                <Button
                  buttonSize="md"
                  color="amazon"
                  startIcon={<Icon iconType="sns-amazon" />}
                  fullWidth
                  sx={{
                    justifyContent: 'start',
                    paddingLeft: theme.spacing(2),
                    '& span': { marginTop: '4px' },
                  }}
                  onClick={() => {
                    Auth.federatedSignIn({
                      provider: CognitoHostedUIIdentityProvider.Amazon,
                    }).then(() => {
                      localStorage.setItem('last_login', 'AMAZON');
                    });
                  }}
                >
                  <Typography fontSize={16} fontWeight={500} paddingLeft={1}>
                    Amazon
                  </Typography>
                </Button>
                {lastLogin === 'AMAZON' && (
                  <Typography
                    fontSize={12}
                    fontWeight={700}
                    color="primary"
                    paddingTop={1}
                  >
                    前回のログインはこちら
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack column center>
                <Button
                  buttonSize="md"
                  color="facebook"
                  startIcon={<Icon iconType="sns-facebook" />}
                  fullWidth
                  sx={{
                    justifyContent: 'start',
                    paddingLeft: theme.spacing(2),
                  }}
                  onClick={() => {
                    Auth.federatedSignIn({
                      provider: CognitoHostedUIIdentityProvider.Facebook,
                    }).then(() => {
                      localStorage.setItem('last_login', 'FACEBOOK');
                    });
                  }}
                >
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    color="white"
                    paddingLeft={1}
                  >
                    facebook
                  </Typography>
                </Button>
                {lastLogin === 'FACEBOOK' && (
                  <Typography
                    fontSize={12}
                    fontWeight={700}
                    color="primary"
                    paddingTop={1}
                  >
                    前回のログインはこちら
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Grid>
          {/* <Button
            onClick={() => {
              Auth.signOut();
            }}
          >
            Logout
          </Button> */}
          {tabValue === '' && (
            <Stack center paddingTop={6}>
              <Button
                onClick={() => router.push('/login/?status=signup')}
                buttonSize="md"
                color="white"
                sx={{
                  width: '289px',
                  border: `solid 1px ${theme.palette.primary.main}`,
                  background: 'unset',
                }}
              >
                新規登録はこちら
              </Button>
            </Stack>
          )}
        </Box>
        {tabValue === 'signup' && (
          <Box paddingTop={4}>
            <Stack center sx={{ padding: '0 24px 56px' }}>
              <Typography
                fontSize={12}
                sx={{ '& a': { color: theme.palette.primary.main } }}
              >
                新規登録するにあたっては、
                <Link href={'/terms'}>
                  <a>利用規約</a>
                </Link>
                に同意するものとします。
              </Typography>
            </Stack>
            <Stack center>
              <Typography fontSize={21} fontWeight={700}>
                iihiでできること
              </Typography>
            </Stack>
            <Box paddingBottom={5}>
              <StyledSwiper
                modules={[Navigation, A11y]}
                slidesPerView={1}
                speed={700}
                loop
                navigation
                style={{ width: '100%' }}
              >
                <SwiperSlide>
                  <Box sx={{ p: 3 }}>
                    <Stack
                      column
                      space={2}
                      sx={{ p: 3, background: '#ffffff', borderRadius: 2 }}
                    >
                      <OptimizedImage
                        src="/assets/images/about/about_list1.png"
                        width="100%"
                        height="100%"
                        alt="日本全国のキャンプ場から探せる"
                      />
                      <Box>
                        <Typography
                          fontSize={14}
                          fontWeight={500}
                          paddingBottom={1}
                        >
                          日本全国のキャンプ場から探せる
                        </Typography>
                        <Typography fontSize={12}>
                          iihiのキャンプ場データベースは日本最大級。こだわりの条件から、行きたいキャンプ場が検索できます。
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box sx={{ p: 3 }}>
                    <Stack
                      column
                      space={2}
                      sx={{ p: 3, background: '#ffffff', borderRadius: 2 }}
                    >
                      <OptimizedImage
                        src="/assets/images/about/about_list2.png"
                        width="100%"
                        height="100%"
                        alt="テーマや場所からキャンプ場が見つかる"
                      />
                      <Box>
                        <Typography
                          fontSize={14}
                          fontWeight={500}
                          paddingBottom={1}
                        >
                          テーマや場所からキャンプ場が見つかる
                        </Typography>
                        <Typography fontSize={12}>
                          トレンドやiihiチームが厳選したキャンプ場リストから、お気に入りのキャンプ場が見つかります。
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box sx={{ p: 3 }}>
                    <Stack
                      column
                      space={2}
                      sx={{ p: 3, background: '#ffffff', borderRadius: 2 }}
                    >
                      <OptimizedImage
                        src="/assets/images/about/about_list3.png"
                        width="100%"
                        height="100%"
                        alt="自分だけのキャンプリストがつくれる"
                      />
                      <Box>
                        <Typography
                          fontSize={14}
                          fontWeight={500}
                          paddingBottom={1}
                        >
                          自分だけのキャンプリストがつくれる
                        </Typography>
                        <Typography fontSize={12}>
                          iihi会員になると、お気に入りのキャンプ場をリストで保存できます。
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box sx={{ p: 3 }}>
                    <Stack
                      column
                      space={2}
                      sx={{ p: 3, background: '#ffffff', borderRadius: 2 }}
                    >
                      <OptimizedImage
                        src="/assets/images/about/about_list4.png"
                        width="100%"
                        height="100%"
                        alt="キャンプ場のレビューを記録・共有できる"
                      />
                      <Box>
                        <Typography
                          fontSize={14}
                          fontWeight={500}
                          paddingBottom={1}
                        >
                          キャンプ場のレビューを記録・共有できる
                        </Typography>
                        <Typography fontSize={12}>
                          キャンプ場のレビューを投稿して、キャンパー同士で情報交換ができます。
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </SwiperSlide>
              </StyledSwiper>
            </Box>
          </Box>
        )}
      </Container>
    </>
  );
};

Index.getLayout = (page) => <LayoutOnlyBody>{page}</LayoutOnlyBody>;

export default Index;
