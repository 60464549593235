import { styled } from '@mui/material/styles';
import type { TabsProps } from '@mui/material/Tabs';
import * as MuiTabs from '@mui/material/Tabs';
import type { SyntheticEvent } from 'react';

import theme from '@/styles/theme';

type Props = TabsProps & {
  tabValue: string;
  onChange?: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
};

const StyledTabs = styled(MuiTabs.default)`
  .MuiTab-root {
    min-width: 130px;
    height: 48px;
    color: ${theme.palette.primary.main} !important;
    font-size: 16px;
    font-weight: bold;
    background: #e3e9e6;
    &.Mui-selected {
      color: #ffffff !important;
      background: ${theme.palette.primary.main};
    }
    :nth-of-type(1) {
      border-radius: 8px 0 0 8px;
    }
    :nth-of-type(2) {
      border-radius: 0 8px 8px 0;
    }
    :not(:last-of-type) {
      margin-right: 6px;
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
`;

const Tabs = ({ children, tabValue, onChange }: Props) => {
  return (
    <StyledTabs value={tabValue} onChange={onChange}>
      {children}
    </StyledTabs>
  );
};

export default Tabs;
