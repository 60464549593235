import { Box, useMediaQuery } from '@mui/material';
import type { ReactNode } from 'react';
import React, { useContext } from 'react';

import DialogSwitcher from '@/contents/Dialogs/DialogSwitcher';
import BreadCrumb from '@/contents/General/BreadCrumb';
import Stack from '@/contents/General/Stack';
import { GlobalContext } from '@/contents/hooks/state';
import { Footer } from '@/layouts/components/Footer';
import { Container, PCArea } from '@/layouts/style';
import theme from '@/styles/theme';
import { Page } from '@/types/domain';

import PCNavi from './components/PCNavi';
import PCSuggest from './components/PCSuggest';

type Props = {
  children: ReactNode;
  noBottomPadding?: boolean;
  // footer非表示
  noFooter?: boolean;
};

const LayoutOnlyBody = ({ children, noBottomPadding, noFooter }: Props) => {
  const { page } = useContext(GlobalContext);
  // @ts-ignore
  const { props } = children;
  const isPC = useMediaQuery('(min-width:900px)');

  return (
    <>
      <Box>
        {isPC && (
          <PCArea>
            <Stack between>
              <PCNavi />
              <PCSuggest />
            </Stack>
          </PCArea>
        )}
        <Container>
          <main style={{ marginTop: 'unset' }}>
            {children}
            <Box
              sx={{
                marginTop: 'auto',

                ...(noBottomPadding
                  ? {}
                  : {
                      marginBottom: theme.spacing(3),
                      padding: `${theme.spacing(10)} ${theme.spacing(3)} 0`,
                    }),
              }}
            >
              <BreadCrumb campSite={props?.campSite} user={props?.user} />
            </Box>

            {!noFooter && page !== Page.LOGIN && page !== Page.EDIT && (
              <Footer />
            )}
          </main>
          <DialogSwitcher />
        </Container>
      </Box>
    </>
  );
};

export default LayoutOnlyBody;
