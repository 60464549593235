export default {
  top: {
    title: 'iihi（いい日） - キャンプ場検索サイト',
    mata_description:
      'みんなでつくるスマホの中のキャンプギア-キャンプ場検索サイト【iihi（いい日）】全国のキャンプ場をおすすめや人気、ランキングから検索できます！',
  },
  about: {
    title: 'iihiについて【みんなでつくるスマホの中のキャンプギア】 - iihi',
    mata_description:
      '数多くのキャンプ場情報が精度高く共有されることで、お気に入りのキャンプ場をもっと見つけやすく、そのキャンプがもっと楽しくなる――そんな新しいギアのような存在になることを目指しています。',
  },
  character: {
    title: 'iihiキャラクターをご紹介 - iihi',
    mata_description:
      'キャンプライフを通じて自然やキャンプのことを楽しみながら学ぶ「イヒヒ」と「はる」の様子をお届けします。',
  },
  theme: {
    title: 'おすすめのキャンプ場ガイド - iihi',
    mata_description:
      '地域やトレンド、ランキングなど、テーマからあなたにぴったりのキャンプ場が見つかる！温泉があるキャンプ場やコテージ泊ができるなど、人気のキャンプ場に出会えます。',
  },
  search: {
    title: 'おすすめのキャンプ場をご紹介 - iihi',
    mata_description:
      'キャンプ場検索サイト【iihi（いい日）】全国のキャンプ場をおすすめやランキングから検索できます！温泉があるキャンプ場やコテージ泊ができるなど、人気のキャンプ場に出会えます。',
  },
  searchNotEntered: {
    title: 'キャンプ場情報が未登録の施設をご紹介 - iihi',
    mata_description:
      'キャンプに行ったら、ぜひiihiに記入・編集、写真を登録いただき、みんなでキャンプ情報を共有、新たなキャンプ場を開拓していきませんか？',
  },
  camp: {
    title: ' - iihi',
    mata_description:
      'のキャンプ場情報はiihiでチェック！キャンプ好きユーザの口コミ・レビュー、写真などのリアルな情報が満載！アクセスや施設情報も充実！',
  },
  campReview: {
    title: 'のレビュー - iihi',
    mata_description: '',
  },
  magazine: {
    title: 'iihi Magazine「火種」 - iihi',
    mata_description:
      'さまざまなプレイヤーに熱源を尋ねるインタビュー企画「火種」では、何を軸・支えに、何を動力として、どうその活動を展開しているのかという、その人の力学をひもときます。',
  },
  news: {
    title: 'お知らせ - iihi',
    mata_description:
      'キャンプ場検索サイト《iihi》のお知らせ一覧です。サービスのアップデート・イベント情報やプレスリリースをお届けします。',
  },
  login: {
    title: '新規会員登録 / ログイン - iihi',
    mata_description:
      '今すぐ会員登録・ログイン！みんなでつくるスマホの中のキャンプギア-キャンプ場検索サイト【iihi（いい日）】全国のキャンプ場をおすすめや人気、ランキングから検索できます！',
  },
  faq: {
    title: 'よくあるご質問《FAQ》 - iihi',
    mata_description:
      'ユーザのみなさまから届いたお問い合わせをよくあるご質問にまとめています。',
  },
  contact: {
    title: '',
    mata_description:
      'いつもiihiをご利用いただきありがとうございます！iihiサービスに関するお問い合わせはこちらからお願いします。',
  },
  feedback: {
    title: 'アイデア募集中 - iihi',
    mata_description:
      'iihiに関するご意見、ご要望、もっとよくするためのアイデアをお聞かせください。ユーザーのみなさんからの意見を参考に、使いやすく、キャンプがもっと楽しくなるサービスへiihiを改善していきます。',
  },
  guideline: {
    title: 'iihiを楽しむためのガイド - iihi',
    mata_description:
      'iihiサービスを楽しむためのコミュニケーションガイドラインをご紹介します。キャンプライフをみんなで楽しみましょう！',
  },
  privacy: {
    title: 'プライバシーポリシー - iihi',
    mata_description: 'iihiサービスのプライバシーポリシーページです。',
  },
  terms: {
    title: '利用規約 - iihi',
    mata_description: 'iihiサービスの利用規約ページです。',
  },
  luckybazaar: {
    title: 'iihiラッキーバザール - iihi',
    mata_description:
      'シーズを集めて、iihiラッキーバザールに出かけませんか？ロトシーズに挑戦すると、うれしいギフトが当たります。',
  },
  aboutSeeds: {
    title: 'シーズとは - iihi',
    mata_description:
      'シーズ（種）とは、iihiを使うと集まるiihiラッキーバザールで使用できるポイントです。',
  },
  sponsor: {
    title: 'ロトシーズの当選品募集 - iihi',
    mata_description:
      'キャンプ場のみなさん、メーカーなど企業のみなさんと一緒に、キャンプの魅力の輪を広げていけたらうれしく思います。',
  },
  firewood: {
    title: '薪1束プレゼント - iihi',
    mata_description:
      'キャンパーのみなさんにもっとキャンプを楽しんでほしいから、iihiと提携キャンプ場から、シーズと交換で薪1束をプレゼント！',
  },
  partnership: {
    title: 'iihi提携キャンプ場 - iihi',
    mata_description:
      'iihiでは、みなさんのキャンプライフをサポートするプレゼントをキャンプ場の方と提携して用意しています。',
  },
  illust: {
    title: 'iihiフリーイラスト - iihi',
    mata_description:
      'iihiでは、キャンプ場やアウトドア施設で活用いただくことを想定した、キャンプのイラスト素材を無償で配布しています。',
  },
  illustList: {
    title: 'イラスト素材一覧《iihiフリーイラスト》 - iihi',
    mata_description:
      'イラスト素材は、キャンプ場内の掲示物や、SNSでのバナー画像など、ご自由にお使いいただけます。',
  },
  damy: {
    title: '',
    mata_description: '',
  },
};
